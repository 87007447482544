<template>
  <div class="admin-settings-trnaslations">
    <b-card
      class="cms-pages-admin"
      :title="$t('Translations')"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Language')">
            <b-form-select
              v-model="selectedLang"
              :options="languages"
              @change="getAdminTranslations"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="sectionPages.length > 0"
          cols="12"
          md="4"
        >
          <b-form-group :label="$t('Choose Language Section')">
            <b-form-select
              v-model="languageSection"
              :options="sectionPages"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template v-if="languageInfo">
        <h4>{{ $t('Save the translations') }}</h4>
        <!-- <vue-json-editor
          v-model="languageInfo"
          :plus="true"
        /> -->
        <div
          class="table-responsive"
          style="max-height: 500px"
        >
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th style="width: 30%">
                  Key
                </th>
                <th style="width: 30%">
                  English Text
                </th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) of languageKeys"
                :key="index"
              >
                <td style="width: 30%">
                  {{ item }}
                </td>
                <td style="width: 30%">
                  {{ flattenEnglishTranslations[item] }}
                </td>
                <td>
                  <input
                    v-model="flattenTranslations[item]"
                    class="form-control"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12 text-center mt-3">
          <b-button
            variant="primary"
            @click="saveAdminTranslations"
          >
            {{ $t('Save Changes') }}
          </b-button>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BFormGroup, BFormSelect } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
// import VueJsonEditor from '@/views/shared/VueJsonEditor.vue'
import _set from 'lodash/set'
import { useAdminUi } from '../useAdmin'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormSelect,
    // VueJsonEditor,
  },
  data() {
    return {
      selectedLang: '',
      languageInfo: null,
      languageSection: '',
      flattenTranslations: {},
      flattenEnglishTranslations: {},
      languages: [
        {
          text: 'Select Langauge',
          value: '',
        },
        {
          text: 'English',
          value: 'en',
        },
        {
          text: 'Arabic',
          value: 'ar',
        },
        {
          text: 'Test Langauge',
          value: 'nah',
        },
      ],
    }
  },
  computed: {
    languageKeys() {
      return Object.keys(this.flattenTranslations).filter(x => x.startsWith(this.languageSection))
    },
    sectionPages() {
      if (!this.languageInfo) return []
      return Object.keys(this.languageInfo).map(x => ({
        text: x,
        value: x,
      }))
    },
  },
  mounted() {
    const { getAdminTranslations } = useAdminUi()
    showLoader()
    getAdminTranslations('en')
      .then(({ data }) => {
        if (data) {
          this.flattenEnglishTranslations = this.mapToFlatObject(data)
        }
        hideLoader()
      })
      .catch(error => {
        showErrorNotification(this, error)
      })
  },
  methods: {
    getAdminTranslations() {
      const { getAdminTranslations } = useAdminUi()
      showLoader()
      getAdminTranslations(this.selectedLang)
        .then(({ data }) => {
          if (data) {
            this.languageInfo = data
            // eslint-disable-next-line prefer-destructuring
            this.languageSection = Object.keys(data)[0]
            this.flattenTranslations = this.mapToFlatObject(this.languageInfo)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    mapToFlatObject(obj, roots = [], sep = '.') {
      return Object.keys(obj).reduce(
        (memo, prop) => ({
          ...memo,
          ...(Object.prototype.toString.call(obj[prop]) === '[object Object]' ? this.mapToFlatObject(obj[prop], roots.concat([prop]), sep) : { [roots.concat([prop]).join(sep)]: obj[prop] }),
        }),
        {},
      )
    },
    saveAdminTranslations() {
      const { saveAdminTranslations } = useAdminUi()
      Object.keys(this.flattenTranslations).forEach(path => {
        _set(this.languageInfo, path, this.flattenTranslations[path])
      })
      showLoader()
      saveAdminTranslations({
        translations: this.languageInfo[this.languageSection],
        lang: this.selectedLang,
        category: this.languageSection,
      })
        .then(({ data }) => {
          if (data) {
            showSuccessNotification(this, 'Translations updates successfully')
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>
